export type Profile =
  | 'EPY_SUPPORT'
  | 'EPY_FINANCIER'
  | 'EPY_BASIC_USER'
  | 'OLP_FINANCIER'
  | 'OLP_SUPPORT'
  | 'OLP_BASIC_USER'
  | 'OLP_FINANCIER_CHINA';

export type ProfileGroup = Profile[];

export const SUPPORT: ProfileGroup = ['EPY_SUPPORT', 'OLP_SUPPORT'];
export const ADMIN: ProfileGroup = [
  'EPY_SUPPORT',
  'EPY_FINANCIER',
  'OLP_FINANCIER',
  'OLP_SUPPORT',
];
export const BASIC: ProfileGroup = [
  'EPY_BASIC_USER',
  'EPY_SUPPORT',
  'EPY_FINANCIER',
  'OLP_BASIC_USER',
  'OLP_SUPPORT',
  'OLP_FINANCIER',
];

export const FINANCIER: ProfileGroup = ['OLP_FINANCIER_CHINA'];

type IUserProfiles = {
  profileAccept: ProfileGroup | ProfileGroup[];
  profile: string[];
};

const checkProfile = ({ profile, profileAccept }: IUserProfiles): boolean => {
  const flattenProfileAccept = Array.isArray(profileAccept[0])
    ? (profileAccept as ProfileGroup[]).flat()
    : (profileAccept as ProfileGroup);

  return profile.some((p) => flattenProfileAccept.includes(p as Profile));
};

export default checkProfile;
