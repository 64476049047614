import React, { createContext, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Sso from '../services/sso';
import sessionStorageService from '../services/localStorage/index';
import { IUserAuthenticatedProps } from './interfaces/index';
import checkProfile, { FINANCIER } from '../helpers/checkProfile';

interface IAuthContextData {
  token: string;
  setTokenAuth: (hash: string) => void;
  user?: IUserAuthenticatedProps;
}

interface IAuthStateData {
  token: string;
}

interface IAuthProps {
  children: JSX.Element[] | JSX.Element;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider = ({ children }: IAuthProps): JSX.Element => {
  const history = useHistory();

  const [user, setUser] = useState({} as IUserAuthenticatedProps);

  const [dataAuthenticate, setDataAuthenticate] = useState<IAuthStateData>(
    () => {
      const token = sessionStorageService.getAccessToken();

      if (token) return { token };

      return {} as IAuthStateData;
    },
  );

  const setTokenAuth = async (hash: string) => {
    if (!dataAuthenticate.token) {
      sessionStorageService.clearAllAccessToken();

      Sso.postSsoLoginAuthenticated(hash).then((response) => {
        const { token } = response.data.body;

        sessionStorageService.setAccessToken(token);
        setDataAuthenticate({ token });

        Sso.getUserAuthenticated(token).then((authResponse) => {
          setUser(authResponse.data.body);

          if (
            checkProfile({
              profile: authResponse.data.body.profile,
              profileAccept: FINANCIER,
            })
          ) {
            history.push('/admin/report');
          } else {
            history.push('/admin');
          }
        });
      });
    }
  };

  useEffect(() => {
    if (dataAuthenticate.token) {
      Sso.getUserAuthenticated(dataAuthenticate.token).then((response) => {
        setUser(response.data.body);
      });
    }
  }, [dataAuthenticate.token]);

  return (
    <AuthContext.Provider
      value={{
        token: dataAuthenticate.token,
        setTokenAuth,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
