import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { HiUserCircle } from 'react-icons/hi';

import ReactGA from 'react-ga4';
import { HeaderUiAdmin } from './style';
import { useAuth } from '../../../hooks/auth';
import Loading from '../../Loading';

const Header = (): JSX.Element => {
  const [loading, setLoading] = useState(true);

  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (user && user?.fullName !== undefined) setLoading(false);
  }, [user]);

  useEffect(() => {
    if (user?.profile) {
      const profile = user.profile.filter(
        (profile) => profile.startsWith('EPY') || profile.startsWith('OLP'),
      );
      ReactGA.event('fly_data', {
        login: user.login,
        profile: profile.length > 0 ? profile[0] : '',
      });
    }
  }, [location.pathname, user]);

  return (
    <HeaderUiAdmin>
      <Grid container alignItems="center">
        <Grid item xs={12} md={6} lg={8} xl={8}>
          <div className="logo-header">
            <span>ePayments</span>
          </div>
        </Grid>
        <Grid item xs={6} md={6} lg={4} xl={4} className="user-area">
          {loading ? (
            <Loading height="20px" width={30} justifyContent="flex-end" />
          ) : (
            <div className="user-header">
              <HiUserCircle size={40} color="#100690" />
              <div className="info-user-header">
                <h4>{user?.fullName}</h4>
                <h5>{user?.email}</h5>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </HeaderUiAdmin>
  );
};

export default Header;
