import { saveAs } from 'file-saver';
import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { ContainerPage, TitleBox } from './style';
import BoxShadow from '../../../../components/BoxShadow/index';
import Button from '../../../../components/Button';
import Cards from '../../../../components/Cards';
import TableEstorno from '../../../../components/Table';
import SuggestionsServices from '../../../../services/sugestions/SugestionsService';
import Loading from '../../../../components/Loading/index';
import CheckoutReportService, {
  ICheckoutReportSearchProps,
} from '../../../../services/report/CheckoutReportService';
import JpMorganReport from '../JpMorganReport';
import { useAuth } from '../../../../hooks/auth';
import type { IUserAuthenticatedProps } from '../../../../hooks/interfaces';
import checkProfile, { FINANCIER } from '../../../../helpers/checkProfile';

interface IApplicationsData {
  application: string;
}

interface IDecisionsData {
  decision: string;
}

interface IsalesOrganizationsData {
  salesOrganization: string;
}

interface IReportColumn {
  id:
    | 'transactionNumber'
    | 'status'
    | 'application'
    | 'paymentAmount'
    | 'userLogin'
    | 'userEmail'
    | 'salesOrganization'
    | 'id'
    | 'paidAt'
    | 'paymentCurrent';
  label: string;
  minWidth?: string;
  align?: 'right';
}

const columns: IReportColumn[] = [
  { id: 'id', label: 'Checkout ID' },
  { id: 'transactionNumber', label: 'Transaction ID' },
  { id: 'paidAt', label: 'Paid in' },
  { id: 'paymentCurrent', label: 'Currency' },
  { id: 'status', label: 'STATUS' },
  { id: 'application', label: 'Application' },
  { id: 'paymentAmount', label: 'Total' },
  { id: 'userLogin', label: 'User Login' },
  { id: 'userEmail', label: 'User E-mail' },
  { id: 'salesOrganization', label: 'Sales' },
];

const Report = (): JSX.Element => {
  const { user } = useAuth();
  const [userState, setUserState] = useState({} as IUserAuthenticatedProps);

  const { t } = useTranslation('report');

  const initialPage = parseInt(process.env.REACT_APP_INITIAL_PAGE || '0', 10);
  const initialRowsPerPage = parseInt(
    process.env.REACT_APP_ROWS_PER_PAGE || '10',
    10,
  );

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [checkouts, setCheckouts] = useState([]);
  const [totalReceived, setTotalReceived] = useState(0);
  const [totalReversed, setTotalReversed] = useState(0);
  const [totalRejected, setTotalRejected] = useState(0);
  const [totalCreated, setTotalCreated] = useState(0);
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [selectedDateInicial, setSelectedDateInicial] = useState<Date | null>(
    null,
  );
  const [selectedDateFinal, setSelectedDateFinal] = useState<Date | null>(null);
  const [selectApplication, setSelectApplication] = useState('');
  const [selectSalesOrganization, setSelectSalesOrganization] = useState('');
  const [selectStatus, setStatus] = useState('');
  const [applicationSuggestions, setApplicationSuggestions] = useState<
    IApplicationsData[]
  >([]);
  const [decisionsSuggestions, setDecisionsSuggestions] = useState<
    IDecisionsData[]
  >([]);
  const [salesOrganizationsSuggestions, setSalesOrganizationsSuggestions] =
    useState<IsalesOrganizationsData[]>([]);

  useEffect(() => {
    if (user && user.login !== undefined) {
      setUserState(user);
      if (
        checkProfile({
          profile: user.profile,
          profileAccept: FINANCIER,
        })
      ) {
        setSelectSalesOrganization('BJGX');
      }
    }
  }, [user]);

  const fetchCheckoutReportData = useCallback(
    (obj?: ICheckoutReportSearchProps) => {
      CheckoutReportService.search({
        application: obj?.application,
        status: obj?.status,
        salesOrganization: obj?.salesOrganization,
        initialCreation: obj?.initialCreation
          ? format(new Date(obj?.initialCreation), 'yyyy-MM-dd')
          : undefined,
        finalCreation: obj?.finalCreation
          ? format(new Date(obj?.finalCreation), 'yyyy-MM-dd')
          : undefined,
        page: obj?.page,
        limit: obj?.limit,
      }).then((r) => {
        setCheckouts(r.data.body.checkouts);
        setTotalCreated(parseInt(r.data.body.numbers.totalCreated, 10));
        setTotalReceived(r.data.body.numbers.totalReceived);
        setTotalRejected(r.data.body.numbers.totalRejected);
        setTotalReversed(r.data.body.numbers.totalReversed);
        setLoading(false);
      });
    },
    [],
  );

  const handleRowsPerPageChange = (rowsPerPageChange: number) => {
    setRowsPerPage(rowsPerPageChange);
    fetchCheckoutReportData({
      application: selectApplication,
      status: selectStatus,
      salesOrganization: selectSalesOrganization,
      initialCreation: (selectedDateInicial || '').toString(),
      finalCreation: (selectedDateFinal || '').toString(),
      page,
      limit: rowsPerPageChange,
    });
  };

  const handlePageChange = (pageChange: number) => {
    setPage(pageChange);
    fetchCheckoutReportData({
      application: selectApplication,
      status: selectStatus,
      salesOrganization: selectSalesOrganization,
      initialCreation: (selectedDateInicial || '').toString(),
      finalCreation: (selectedDateFinal || '').toString(),
      page: pageChange,
      limit: rowsPerPage,
    });
  };

  const handleDateChangeInicial = (date: any) => {
    setSelectedDateInicial(date);
  };

  const handleDateChangeFinal = (date: any) => {
    setSelectedDateFinal(date);
  };

  const handleChangeApplication = (
    event: React.ChangeEvent<{ value: any }>,
  ) => {
    setSelectApplication(event.target.value);
  };

  const handleChangeSalesOrganization = (
    event: React.ChangeEvent<{ value: any }>,
  ) => {
    setSelectSalesOrganization(event.target.value);
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: any }>) => {
    setStatus(event.target.value);
  };

  const handleSubmitFilter = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setPage(initialPage);

    let sales = selectSalesOrganization;
    if (
      userState.profile &&
      checkProfile({ profile: userState.profile, profileAccept: FINANCIER })
    ) {
      sales = 'BJGX';
    }

    fetchCheckoutReportData({
      application: selectApplication,
      status: selectStatus,
      salesOrganization: sales,
      initialCreation: (selectedDateInicial || '').toString(),
      finalCreation: (selectedDateFinal || '').toString(),
      page: initialPage,
      limit: rowsPerPage,
    });
  };

  const handleClearValues = () => {
    setLoading(true);
    setPage(initialPage);
    setSelectedDateInicial(null);
    setSelectedDateFinal(null);
    setSelectApplication('');
    setSelectSalesOrganization('');
    setStatus('');
    if (
      userState.profile &&
      checkProfile({ profile: userState.profile, profileAccept: FINANCIER })
    ) {
      fetchCheckoutReportData({
        salesOrganization: 'BJGX',
      });
      setSelectSalesOrganization('BJGX');
    } else {
      fetchCheckoutReportData();
      setSelectSalesOrganization('');
    }
  };

  const handleDownloadReport = () => {
    CheckoutReportService.download({
      application: selectApplication,
      status: selectStatus,
      salesOrganization: selectSalesOrganization,
      initialCreation: (selectedDateInicial || '').toString(),
      finalCreation: (selectedDateFinal || '').toString(),
    }).then((r) => {
      if (r.data.size === 0) {
        enqueueSnackbar(t('No data was found to generate the report.'), {
          variant: 'warning',
        });
        return;
      }
      const type =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      saveAs(new Blob([r.data], { type }), 'report.xlsx');
    });
  };

  useEffect(() => {
    SuggestionsServices.getApplicationsSuggestions().then((response) => {
      const { applications, decisions, salesOrganizations } =
        response.data.body;
      setApplicationSuggestions(applications);
      setDecisionsSuggestions(decisions);
      setSalesOrganizationsSuggestions(salesOrganizations);
    });

    if (userState.profile) {
      const fetchData = {
        page: initialPage,
        limit: initialRowsPerPage,
        ...(userState.profile &&
          checkProfile({
            profile: userState.profile,
            profileAccept: FINANCIER,
          }) && { salesOrganization: 'BJGX' }),
      };
      fetchCheckoutReportData(fetchData);
    }
  }, [fetchCheckoutReportData, initialPage, initialRowsPerPage, userState]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ContainerPage>
        <Grid container>
          <Grid item xs={12}>
            <form onSubmit={handleSubmitFilter}>
              <BoxShadow>
                <Grid container>
                  <Grid item xs={12}>
                    <TitleBox>Filtros</TitleBox>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControl className="style-select">
                      <InputLabel
                        id="select-application"
                        className="input-label"
                      >
                        {t('Application')}
                      </InputLabel>
                      <Select
                        labelId="select-application"
                        id="select-application-input"
                        value={selectApplication}
                        onChange={handleChangeApplication}
                      >
                        {applicationSuggestions.map((item) => (
                          <MenuItem
                            key={item.application}
                            value={item.application}
                          >
                            {item.application.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControl className="style-select">
                      <InputLabel
                        id="select-sales-organization"
                        className="input-label"
                      >
                        {t('Sales Organization')}
                      </InputLabel>
                      <Select
                        labelId="select-sales-organization"
                        id="select-sales-organization-input"
                        value={selectSalesOrganization}
                        onChange={handleChangeSalesOrganization}
                        disabled={
                          userState.profile &&
                          checkProfile({
                            profile: userState.profile,
                            profileAccept: FINANCIER,
                          })
                        }
                      >
                        {salesOrganizationsSuggestions.map((item) => (
                          <MenuItem
                            key={item.salesOrganization}
                            value={item.salesOrganization}
                          >
                            {item.salesOrganization.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={2}>
                    <FormControl className="style-select">
                      <InputLabel id="select-status" className="input-label">
                        {t('Status')}
                      </InputLabel>
                      <Select
                        labelId="select-status"
                        id="select-status-input"
                        value={selectStatus}
                        onChange={handleChangeStatus}
                      >
                        {decisionsSuggestions.map((item) => (
                          <MenuItem key={item.decision} value={item.decision}>
                            {item.decision.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={2}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-inicial"
                      label={t('Initial Date')}
                      format="MM/dd/yyyy"
                      value={selectedDateInicial}
                      onChange={handleDateChangeInicial}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={2}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-final"
                      label={t('Final Date')}
                      format="MM/dd/yyyy"
                      value={selectedDateFinal}
                      onChange={handleDateChangeFinal}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="flex-end" spacing={2}>
                  <Grid item xs={12} lg={3} xl={2}>
                    <Button
                      text={t('Clear')}
                      type="button"
                      className="cancel"
                      onClick={handleClearValues}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3} xl={2}>
                    <Button
                      text={t('Export to Excel')}
                      type="button"
                      className="estorno"
                      onClick={handleDownloadReport}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3} xl={2}>
                    <Button
                      text={t('Search')}
                      type="submit"
                      className="search-report"
                    />
                  </Grid>
                </Grid>
              </BoxShadow>
            </form>
          </Grid>
        </Grid>

        <Grid container className="mt-16">
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Cards
                  text={t('Total Received')}
                  value={totalReceived.toString()}
                  className="blue-light"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Cards
                  text={t('Total Refunded')}
                  value={totalReversed.toString()}
                  className="yellow"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Cards
                  text={t('Total Rejected')}
                  value={totalRejected.toString()}
                  className="red"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Cards
                  text={t('Total of Created Checkouts')}
                  value={totalCreated.toString()}
                  className="blue-dark"
                />
              </Grid>
            </Grid>
            <Grid container className="mt-16">
              <Grid item xs={12}>
                <BoxShadow>
                  {loading ? (
                    <Loading />
                  ) : (
                    <TableEstorno
                      rows={checkouts}
                      columns={columns}
                      showBtn={false}
                      title={t('Results')}
                      handlePageChange={handlePageChange}
                      handleRowsPerPageChange={handleRowsPerPageChange}
                      totalRows={totalCreated}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      t={t}
                    />
                  )}
                </BoxShadow>
              </Grid>
            </Grid>
            {/* <JpMorganReport /> */}
          </Grid>
        </Grid>
      </ContainerPage>
    </MuiPickersUtilsProvider>
  );
};

export default Report;
